import * as React from 'react';
import classNames from 'classnames';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { WPhotoWrapper } from '../../WPhotoWrapper';
import Link from '../../../../Link/viewer/Link';
import WPhoto from '../../WPhotoBase';
import { isEmptyObject } from '../../../../../core/commons/utils';
import { selectProperComponent, getPropsForLink } from '../../../utils';
import skinsStyle from './styles/skins.scss';

const IronPhotoSkin: React.FC<SkinWPhotoProps> = ({
  id,
  link,
  imageProps,
  title,
  onClick,
  hasPlatformClickHandler = false,
  onClickBehavior,
  onDblClick,
  onMouseEnter,
  onMouseLeave,
  filterEffectSvgString,
  filterEffectSvgUrl,
}) => {
  const ImageComp = selectProperComponent(onClickBehavior);
  const isPopUp = onClickBehavior === 'zoomMode';
  const linkProps = getPropsForLink({
    onClickBehavior,
    className: skinsStyle.link,
    link,
  });

  return (
    <WPhotoWrapper
      id={id}
      className={skinsStyle.IronPhoto}
      onClick={onClick}
      onDblClick={onDblClick}
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      withOnClickHandler={
        !isEmptyObject(link) || hasPlatformClickHandler || isPopUp
      }
      filterEffectSvgString={filterEffectSvgString}
      filterEffectSvgUrl={filterEffectSvgUrl}
    >
      <Link {...linkProps}>
        <ImageComp
          id={`img_${id}`}
          {...imageProps}
          className={skinsStyle.image}
          link={isPopUp ? link : undefined}
        />
        <div className={skinsStyle.imgGlow}></div>
        <div className={classNames(skinsStyle.screwTL, skinsStyle.screw)}></div>
        <div className={classNames(skinsStyle.screwTR, skinsStyle.screw)}></div>
        <div className={classNames(skinsStyle.screwBL, skinsStyle.screw)}></div>
        <div className={classNames(skinsStyle.screwBR, skinsStyle.screw)}></div>
      </Link>
    </WPhotoWrapper>
  );
};

const IronPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={IronPhotoSkin} />
);

export default IronPhoto;
