import WPhoto_IronPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/IronPhoto/IronPhoto.skin';
import WPhoto_IronPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_IronPhoto = {
  component: WPhoto_IronPhotoComponent,
  controller: WPhoto_IronPhotoController
};


export const components = {
  ['WPhoto_IronPhoto']: WPhoto_IronPhoto
};


// temporary export
export const version = "1.0.0"
